<template>
  <v-container>
    <execution-type-list/>
  </v-container>
</template>

<script>
import ExecutionTypeList from "@/components/app_setting/execution_types/ExecutionTypeList";

export default {
  name: "ExecutionTypePage",
  components: {
    ExecutionTypeList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Execution Type'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>